<template>
    <div>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form class="p-3 p-sm-2" @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                <b-overlay :show="loading">
                    <b-table-simple responsive hover bordered>
                        <b-thead>
                            <b-tr>
                                <b-th class="text-center" width="20%">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th class="text-center">{{ $t('bazarMonitoring.market_name') }}</b-th>
                                <b-th class="text-center" width="25%">{{ $t('globalTrans.price') }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <template v-if="listData.length">
                                <b-tr v-for="(item, index) in listData" :key="index">
                                    <b-td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</b-td>
                                    <b-td class="text-left">{{ getMarketDirectoryName(item.market_directory_id) }}</b-td>
                                    <b-td class="text-center">
                                        <ValidationProvider v-if="isAdminTypeUser && item.status !== 3" name="Amount" vid="amount" rules="required|min_value:0" v-slot="{ valid, errors }">
                                            <b-form-input
                                                v-if="priceType === 1"
                                                id="amount"
                                                v-model="item.lowest_price_amount"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                            <b-form-input
                                                v-else
                                                id="amount"
                                                v-model="item.highest_price_amount"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </ValidationProvider>
                                        <template v-else>
                                            {{ priceType === 1 ? $n(item.lowest_price_amount) : $n(item.highest_price_amount) }}
                                        </template>
                                    </b-td>
                                </b-tr>
                            </template>
                            <b-tr v-else>
                                <b-td class="text-center" colspan="3">{{ $t('globalTrans.noDataFound') }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-row class="text-right">
                        <b-col>
                            <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.save') }}</b-button>
                            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-details')">{{ $t('globalTrans.cancel') }}</b-button>
                        </b-col>
                    </b-row>
                </b-overlay>
            </b-form>
        </ValidationObserver>
    </div>
</template>
<script>
import RestApi, { bazarMonitoringServiceBaseUrl } from '@/config/api_config'
import { marketDirectoryList, updatePriceAmount } from '../../api/routes'
export default {
    name: 'Details',
    props: ['search', 'priceType', 'callSearchData'],
    data () {
        return {
            listData: []
        }
    },
    created () {
        this.loadData()
    },
    computed: {
        loading: function () {
            return this.$store.state.commonObj.loading
        },
        currentLocale () {
            return this.$i18n.locale
        },
        isAdminTypeUser () {
            return this.$store.state.Auth.activeRoleId === 1 || this.$store.state.Auth.authUser.org_admin === 2
        }
    },
    methods: {
        async loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const result = await RestApi.getData(bazarMonitoringServiceBaseUrl, marketDirectoryList, this.search)
            this.listData = result.data
            this.$store.dispatch('mutateCommonProperties', { loading: false })
        },
        getMarketDirectoryName (id) {
            const obj = this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.find(item => item.value === id)
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        async saveUpdate () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const loadingState = { loading: false, listReload: false }
            const data = Object.assign({}, this.search, { price_type: this.priceType, listData: this.listData })
            const result = await RestApi.postData(bazarMonitoringServiceBaseUrl, updatePriceAmount, data)

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.callSearchData()
                this.$bvModal.hide('modal-details')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
